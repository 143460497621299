const investment = [
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image1.svg',
  },
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image2.svg',
  },
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image3.svg',
  },
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image4.svg',
  },
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image5.svg',
  },
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image6.svg',
  },
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image7.svg',
  },
  {
    name: 'Rank Capital Credit Financing',
    profit: 9.2,
    duration: 6,
    price: 50,
    investors: 2000,
    availability: 'Sold out',
    image: 'image8.svg',
  },
];

export default investment;
